import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import './mystyles.scss';

const ImpressumPage = ({ pageContext }) => {
  return (
    <Layout title="Impressum" pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <h1 className="title">Impressum</h1>
          <h2 className="subtitle">Angaben gemäß § 5 TMG</h2>
          <p className="block">
          zentrust partners GmbH<br/>
          Birnenweg 11<br/>
          65205 Wiesbaden<br/>
          Deutschland
          </p>
          <p className="block">
          Registergericht: Amtsgericht Wiesbaden, HRB 34219<br/>
          Vertreten durch{' '}
          <Link to="/partner/sven-fassbender">Sven Faßbender</Link>,{' '}
          <Link to="/partner/martin-tschirsich">Martin Tschirsich</Link>
          </p>
          <p className="block">
          Umsatzsteuer-Identifikationsnummer: DE349385958<br/>
          </p>
          <p className="block">
          E-Mail: <a href="mailto:contact@zentrust.partners">contact@zentrust.partners</a><br/>
          Tel.: <a href="tel:+4961227076736">+49 6122 7076736</a><br/>
          Fax.: <a href="fax:+4961227076735">+49 6122 7076735</a><br/>
          </p>
          <h2 className="subtitle">Verantwortlicher i.S.d. §18 Abs. 2 MStV</h2>
          <p className="block">
          <Link to="/partner/martin-tschirsich">Martin Tschirsich</Link><br/>
          Birnenweg 11<br/>
          65205 Wiesbaden<br/>
          Deutschland
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default ImpressumPage;
